.energy-matrix-container {
    position: relative;
}

.energy-matrix-container .label {
    position: absolute;
    font-size: 0.625rem;
    z-index: 10;
}

.energy-matrix-container .quality-label {
    bottom: 0;
}

.energy-matrix-container .quality-positive {
    right: 30%;
}

.energy-matrix-container .quality-title {
    right: 59%;
    max-width: 50px;
}

.energy-matrix-container .quality-negative {
    right: 92%;
}

.energy-matrix-container .intensity-label {
    left: 0;
}

.energy-matrix-container .intensity-high {
    top: 0;
}

.energy-matrix-container .intensity-title {
    top: 45%;
    max-width: 50px;
}

.energy-matrix-container .intensity-low {
    top: 92%;
}
