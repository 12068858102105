nav.breadcrumbs {
    display: flex;
    align-items: center;
    margin: 0 var(--gutter-small) !important;

    ol {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding-left: 0;
        margin: 0;

        li {
            position: relative;
            padding-right: 20px;

            a,
            span {
                text-decoration: none;
                transition: color 0.3s ease;
                color: var(--color-accent-300);
            }

            a:hover,
            span:hover {
                color: var(--color-accent-200);
            }
        }

        li[aria-current] {
            a, span {
                color: var(--color-accent-100);
            }
        }

        li:not(:last-child)::after {
            content: '/';
            position: absolute;
            right: 5px;
            font-weight: bold;
        }

    }
}
