tag-list {
    display: block;
    margin-bottom: 10px;
    width: 100%;

    tag-item {
        background: var(--color-primary-200);
        padding: var(--gutter-small) var(--gutter-small) var(--gutter-small) var(--gutter-small);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: var(--gutter-tiny);
        font-size: 14px;
        font-weight: 400;
        width: 100%;

        h4 {
            display: block;
            margin-bottom: 0;
        }

        div.controls {
            margin-left: auto;
            flex-shrink: 0;
            flex-grow: 0;
            text-align: right;
        }
    }
}
