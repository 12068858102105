form {
    p.help-text {
        color: #818078;
        margin-top: -15px;
        margin-left: 20px;
    }
}

form {
    div.container {
        div.entry.inline {
            display: flex;

            * {
                width: auto;
                flex-grow: 1;
                justify-content: center;

                input, select {
                    width: 100%;
                }
            }
        }
    }
}

form {
    div.flex.expanded {
        width: 100vw;
        gap: 0;
        flex-wrap: wrap;

        label:has(input.variant1) {
            width: 190px;
        }
    }
}
