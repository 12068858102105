data-ranking {
    .group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: var(--gutter-mini);
        padding: var(--gutter-small);
        background: var(--color-primary-100);
        border-radius: 100px;

        &.active {
            background: var(--color-accent-400);
            color: var(--color-primary-200);

            .count,
            .name h5 {
                color: var(--color-primary-200);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .count {
            font-weight: 400;
            width: 50px;
            color: var(--color-text-100);
            margin: 0;
        }

        .score {
            h2 {
                margin: 0;
            }
        }

        .name {
            margin-left: auto;

            h5 {
                margin: 0;
                font-weight: 400;
                color: var(--color-text-100);
            }
        }
    }
}
