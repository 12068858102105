translatable-field {
    display: flex;

    *:not(button) {
        flex-grow: 1;
    }
    button {
        height: 100%;
        margin-top: 4px;
        padding-top: 14px !important;
        padding-bottom: 14px !important;
    }
    button[data-outline] {
        background-color: var(--color-primary-100) !important;
        color: var(--color-primary-000) !important;
        svg-icon {
            --icon-fill-color: var(--color-primary-000) !important;
        }
    }
}

language-container {
    position: relative;

    svg {
        max-width: 25px;
        position: absolute;
        left: 10px;
        top: 12px;
        height: 15px;
    }

    input {
        width: 100%;
        padding-left: 40px !important;
    }
    input.ai-translated {
        color: var(--color-accent-300);
    }
    input.edited {
        color: var(--color-accent-200);
    }
    input::placeholder {
        opacity: 0.5;
    }
}
language-controls {
    display: flex;
    *:not(:last-child) {
        flex-grow: 1;
        margin-right: 10px;
        text-align: center;
    }
}
