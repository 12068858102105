@use "sass:math";
@use "sass:map";

@use "@qogni-technologies/design-system/src/scss/functions";

main-grid > *:not(page-enter) {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: calc(var(--gutter-tiny) * -1);

    > * {
        width: calc(100% - var(--gutter-small));
        max-width: calc(100% - var(--gutter-small));
        flex-basis: calc(100% - var(--gutter-small));
        margin: var(--gutter-tiny) !important;

        $columns: 12;
        @for $size from 1 through $columns {
            &.col-#{$size} {
                flex-basis: calc(math.percentage(functions.divide($size, $columns)) - var(--gutter-small));
                max-width: calc(math.percentage(functions.divide($size, $columns)) - var(--gutter-small));
                width: calc(math.percentage(functions.divide($size, $columns)) - var(--gutter-small));
            }
        }
    }
}


@media only screen and (max-width: 1600px) {
    main-grid > * > * {
        min-width: calc(50% - var(--gutter-small));
    }
}

@media only screen and (max-width: 991px) {
    main-grid > * > * {
        min-width: calc(100% - var(--gutter-small));
    }
}
