page-header {
    background: var(--color-primary-200);
    border-radius: 100px;
    height: 70px;
    padding: 0 var(--gutter-small);

    h1 {
        font-size: 22px;
        margin: 0;
    }

    p {
        line-height: 1.25;
        font-size: .9rem;
        color: var(--color-text-100);
        margin: 0;
    }

    .flex-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;

        nav {
            margin-left: auto;

            ul {
                margin: 0;
                padding: 0;

                li {
                    margin: 0 0 0 var(--gutter-tiny);
                    padding: 0;
                    list-style: none;
                    display: inline-block;
                    transition: color var(--transition-duration) ease-in-out;

                    a {
                        text-decoration: none;
                        color: var(--color-primary-000);

                        &:hover,
                        &:focus,
                        &.active {
                            color: var(--color-accent-100);
                        }
                    }
                }
            }
        }

        .extra {
            justify-content: center;
            margin-left: var(--gutter);

            select {
                padding-right: 50px;
            }
        }

        account-link {
            margin-left: var(--gutter-small);

            a {
                padding: 0;
                margin: -2px 0 0 0;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    page-header .flex-wrapper {
        > *:first-child,
        nav {
            display: none;
        }

        account-link {
            margin-left: auto;
        }
    }
}
