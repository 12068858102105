data-table, .data-table {
    overflow-x: auto;
    display: block;

    table {
        width: 100%;
        border-spacing: 0;
        font-size: 14px;

        th {
            color: var(--color-text-100);
            font-weight: 400;
            cursor: pointer;
            transition: color 0.3s ease-in-out;

            &.active-sort {
                font-weight: 700;
            }

            &.sortable {
                &:hover,
                &:focus,
                &.active-sort {
                    color: var(--color-accent-400);

                    .wrap svg-icon {
                        --icon-fill-color:var(--color-accent-400);
                    }
                }
            }

            .wrap {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                svg-icon {
                    --icon-fill-color: var(--color-text-100);
                    margin-left: var(--gutter-mini);
                }
            }
        }

        th, td {
            text-align: left;
            padding: 10px;
            border-bottom: 1px solid var(--color-border);
        }

        tr:nth-child(odd) {
            td {
                background: var(--color-primary-100);
            }

            th,
            td {
                border-color: var(--color-primary-100);
            }
        }

        tr:hover,
        &:focus {
            td {
                background: var(--color-accent-300);
                color: var(--color-primary-200);
            }
        }

        td.bold {
            font-weight: bold;
        }
        td.small {
            font-size: 0.7rem;
        }
    }

    .pagination-controls {
        margin-top: var(--gutter-tiny);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: .9rem;

        > * {
            margin-left: var(--gutter-tiny);
        }
    }
}

section.search-filter form {
    display: flex;
    flex-direction: row;
    justify-content: center;

    *:not(:last-child) {
        margin-right: 10px;
        flex-grow: 1;
    }
}

section.search-filter.search:not(.filter) form {
    label {
        width: 100%;
    }
}
