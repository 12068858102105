comparison-stat {
    .negative {
        .score {
            color: var(--color-accent-200);
        }

        .comparison .change {
            color: var(--color-accent-200);

            svg-icon {
                --icon-fill-color: var(--color-accent-200);
                transform: rotate(90deg);
            }
        }
    }

    .icon {
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        --icon-fill-color: var(--color-primary-200);
        transition: background var(--transition-duration) ease-in-out;
        background: var(--color-primary-000);
    }

    flex-container {
        margin: 0;
    }

    .score {
        font-size: 50px;
        font-weight: 700;
        line-height: .9;
        margin-bottom: var(--gutter-small);
        color: var(--color-accent-400);
    }

    .comparison {
        .change {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: var(--color-accent-400);
            font-weight: 500;

            svg-icon {
                margin-right: var(--gutter-mini);
                position: relative;
                transform: rotate(-90deg);
                --icon-fill-color: var(--color-accent-400);
            }
        }

        .label {
            color: var(--color-text-100);
        }
    }
}

comparison-stat[size="small"] {
    h3 {
        margin-bottom: 0;
    }

    .score {
        font-size: 30px;
        margin-bottom: 0;

        count-up {
            font-size: 28px;
        }
    }

    .icon {
        height: 30px;
        width: 30px;

        svg-icon {
            --icon-size: 16px;
        }
    }

    .comparison {
        .change {
            svg-icon {
                --icon-size: 16px;
            }
        }
    }
}
