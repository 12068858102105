
program-sidebar {
    display: block;

    > a.button {
        position: relative;
        float: right;
    }

    ul.program-structure {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        max-height: 75vh;
        overflow: auto;

        li {
            display: flex;
            align-items: center;
            padding: 2px 5px;
            cursor: pointer;

            a:hover {
                color: var(--color-accent-300);

                svg-icon {
                    --icon-fill-color: var(--color-accent-300);
                }
            }

            a:active, a.active {
                color: var(--color-accent-400);

                svg-icon {
                    --icon-fill-color: var(--color-accent-400);
                }
            }

            a {
                color: var(--color-accent-200);
                transition: color 0.2s ease-in-out;

                :visited {
                    color: var(--color-accent-200);
                }

                svg-icon {
                    display: inline-block;
                    --icon-fill-color: var(--color-accent-200);
                }
            }
        }
    }

}

day-block {
    display: block;
    margin-bottom: var(--gutter-small);

    day-entry {
        display: flex;
        border: 1px solid rgba(0,0,0,0.2);
        padding: 8px 10px;
        margin: var(--gutter-mini) 6px;
        border-radius: 10px;

        div.ac-container {
            position: relative;
        }

        &[data-empty] {
            border-style: dashed;
            display: flex;

            div {
                flex-grow: 2;
                margin-right: var(--gutter-mini);

                input[type="search"] {
                    margin-bottom: 0;
                }
            }

            button {
                flex-grow: 1;
                margin-right: var(--gutter-mini);
            }
        }

        svg-icon {
            display: inline-block;
            --icon-size: 26px;
            margin-right: var(--gutter-mini);
        }
        span {
            display: inline-block;
            flex-grow: 1;
        }
        day-entry-controls {
            display: inline-block;
        }
    }
}
