@use "sass:math";
@use "sass:map";

@use "@qogni-technologies/design-system/src/scss/functions";

stat-leaderboard {
    .data-table {
        max-height: 400px;
        overflow-y: auto;
    }
}

.tab-container.tiny  {
    margin: 0 !important;

    > label {
        margin: var(--gutter-mini) var(--gutter-tiny) !important;

        span {
            padding: var(--gutter-mini) var(--gutter-small) var(--gutter-mini) var(--gutter-tiny) !important;
        }
    }

    > label input:not(:checked) + span {
        background-color: var(--color-primary-200) !important;
    }
    > label input:not(:checked) + span:before {
        background-color: var(--color-primary-100) !important;
    }
}

dashboard-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: calc(var(--gutter-tiny) * -1);

    > * {
        width: calc(100% - var(--gutter-small));
        max-width: calc(100% - var(--gutter-small));
        flex-basis: calc(100% - var(--gutter-small));
        margin: var(--gutter-tiny) !important;

        $columns: 12;
        @for $size from 1 through $columns {
            &.col-#{$size} {
                flex-basis: calc(math.percentage(functions.divide($size, $columns)) - var(--gutter-small));
                max-width: calc(math.percentage(functions.divide($size, $columns)) - var(--gutter-small));
                width: calc(math.percentage(functions.divide($size, $columns)) - var(--gutter-small));
            }
        }
    }


    @media only screen and (max-width: 1600px) {
        > * {
            min-width: calc(50% - var(--gutter-small));
        }
    }

    @media only screen and (max-width: 991px) {
        > * {
            min-width: calc(100% - var(--gutter-small));
        }
    }
}

stat-calculator {
    slider-container {
        display: flex;

        > *:first-child {
            margin-right: 5px;
        }

        > *:last-child {
            margin-left: 5px;
        }
    }

    flex-container:first-child {
        flex-item:first-child {
            padding-right: var(--gutter-small);
        }
        flex-item:last-child {
            padding-left: var(--gutter-small);
        }
    }
    flex-container:last-child {
        margin-bottom: 0;
    }

    .card.output-card {
        output-value {
            font-size: 1.9rem;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 0;
        }
    }

    slider-output {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: bold;
        position: relative;
        top: -25px;
    }

    range-slider {
        width: 100%;

        input {
            --track-color: var(--color-primary-200);
        }
    }
}
