.app-canvas {
    display: block;
    max-width: 2400px;
    margin: 0 auto;
    background: var(--color-primary-100);

    main {
        height: 100%;
        min-height: 100vh;
        padding: var(--gutter-small) var(--gutter-small) 100px var(--gutter-small);
    }

    aside {
        position: fixed;
        left: 0;
        top: 0;
        width: 80px;
        height: 100%;
        z-index: 9;
        transform: translateX(-100%);
        transition: transform var(--transition-duration) ease-in-out;
        will-change: transform;
    }

    footer {
        z-index: 12;

        bottom-bar {
            display: block;
            position: fixed;
            z-index: 10;
            left: 0;
            bottom: -200px;
            width: 100%;
            transition: bottom .2s ease-in-out;
        }

        @media only screen and (max-width: 600px) {
            bottom-bar {
                bottom: 0px;
                transition: bottom .2s ease-in-out;
            }
        }
    }

    &.no-sidebar {
        aside {
            display: none;
        }
        main {
            margin-left: 0;
        }
    }
    &.no-bottom-bar {
        footer bottom-bar {
            display: none;
        }
    }

    header {
        max-width: 800px;
        text-align: center;

        > *:last-child {
            margin-bottom: 0;
        }
    }
}

.is-authenticated {
    .app-canvas {
        main {
            margin-left: 80px;
            padding: var(--gutter-small);
            transition: none;
        }

        aside {
            transform: none;
        }

        &.no-sidebar {
            aside {
                display: none;
            }
            main {
                margin-left: 0;
            }
        }
    }
}

.is-authenticated {
    .app-canvas {
        main {
            margin-left: 300px;
            padding: var(--gutter-small);
        }

        aside {
            width: 300px;
            transition: none;
        }

        @media only screen and (max-width: 900px) {
            main {
                margin-left: 80px;
                padding: var(--gutter-tiny) var(--gutter-tiny) 120px var(--gutter-tiny);
            }

            aside {
                width: 80px;

            }

            &.no-sidebar {
                aside {
                    display: none;
                }
                main {
                    margin-left: 0;
                }
            }
        }

        @media only screen and (max-width: 600px) {
            main {
                margin-left: 0;
            }

            aside:not(:has(side-bar.slide)) {
                left: -600px;
                transition: left .3s ease-in-out;
            }

            &.no-sidebar {
                aside {
                    display: none;
                }
                main {
                    margin-left: 0;
                }
            }
        }

        header {
            text-align: left;
            max-width: 100%;
        }
    }
}

.mb-none { margin-bottom: 0; }
.mt-none { margin-top: 0; }
.me-none { margin-right: 0; }
.ms-none { margin-left: 0; }
.w-100 { width: 100%; }

@media only screen and (min-width: 2400px) {
    .is-authenticated .app-canvas aside {
        left: 50%;
        transform: translateX(-1200px);
        transition: none;
    }
}

.flex {
    gap: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    &.spread {
        justify-content: space-between;
    }
}


[data-tooltip] {
    z-index: 50;
}
