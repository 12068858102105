account-link {
    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: var(--gutter-small);
        color: var(--color-primary-000);
        text-decoration: none;
        padding: var(--gutter-small) 0 0 var(--gutter-small);

        figure {
            width: 40px;
            height: 40px;
            position: relative;
            margin: 0 var(--gutter-small) 0 0;

            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
                border: 3px solid var(--color-primary-200);
                border-radius: 100%;
            }

            svg-icon {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 5;
                background: var(--color-accent-200);
                width: 20px;
                height: 20px;
                transform: translate(25%, -25%);
                border-radius: 100%;
                color: var(--color-primary-200);
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    account-link a strong {
            display: none;
    }
}
