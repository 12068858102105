html {
    &:not(.is-authenticated) {
        .not-anonymous {
            display: none !important;
        }
    }
}

[data-text] {
    display: inline-block
}

@media only screen and (max-width: 900px) {
    [data-text] {
        display: none;
    }
}

html {
    body {
        &::before {
            content: "";
            position: fixed;
            top: 0;
            left: -100vw;
            width: 100vw;
            height: 6px;
            background-color: var(--color-accent-300);
            z-index: 5;
        }

    }

    /* activate global progress bar */
    &.busy {
        body {
            overflow-x: hidden;

            &::before {
                animation: progress 1s linear infinite;
            }
        }
    }
}

/* Ghost UI */
.ghost {
    pointer-events: none;
    opacity: .6;
    transition: all .1s;
}

@keyframes progress {
    50% {
        left: 0;
    }

    100% {
        left: 100vw
    }
}

.right {
    float: right;
    right: 0;
}

.text-end {
    text-align: end;
}
.text-start {
    text-align: start;
}
