page-logo {
    display: block;
    margin-right: 30px;
    padding-right: 30px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: var(--color-primary-000);
    }

    figure {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 100%;
            height: 35px;
            object-fit: contain;
            object-position: left center;
        }
    }
}
